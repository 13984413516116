import React, { useEffect, useContext, useState, useRef, useMemo } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext";

import { useNetworkingHooks } from "../helpers/NetworkMessaging";
import { CoreGameConnectionsHooks } from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner } from "../Components/PagePrefabs";
import { GenericReticule, shuffleboardBkgSplash, shuffleboardLogo, sumoTouchArea } from "../Sprites/ImagesLoader";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameplayArea } from "../Components/GameplayPrefabs";


export default function ChuckIt(props){ 
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context, gameStateContext, teamsContext);

    const [Invert, setInvert]= useState(false);
    const [SliderValue, setSliderValue] = useState(50);

   
    useEffect(() => {
        //OnMount Things happen here
        document.title = "Chuck It Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        
        if(websocket.sessionId === '') Reconnect();

        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});
        
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    function onPayload(id, payload, senderContext){
        
        //console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload, senderContext);
        CheckReconnectMessages(id,payload, senderContext);

        //Game specific calls
        switch(id){
          
            default:
                break;
        }
    }

   
    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("ChuckIt_Aim", payload);
    }

    function TrackingEnded(){
        SendSpecificMessageToServer("ChuckIt_Fire");
    }

    function UpdateSliderValue(value){
        setSliderValue(value);
    }

    const TouchInfo = {
        bkg: sumoTouchArea,
        reticule: GenericReticule,
        sendArray: false,
        rate: 15,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded

    }
    

    return (
        <>
            <PageBackground bkgImage={shuffleboardBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!gameStateContext.GameState.joinedGame} Logo={shuffleboardLogo}/>
            <PlayerBanner myColor={teamsContext.Teams.myteam.color}/>
            {!gameStateContext.GameState.teamsSelected ? 
                <TeamSelection TeamContext={teamsContext} GameState={gameStateContext.GameState} smts={(msg) => SendSpecificMessageToServer(msg)} /> 
                : 
                <GameplayArea 
                    game="ChuckIt" 
                    TouchBoxInfo={TouchInfo} 
                    useBlur={!gameStateContext.GameState.myTurn} 
                    GetSliderValue={(value) => UpdateSliderValue(value) } 
                    GameContext={gameStateContext}
                    TeamsContext={teamsContext}
                    Logo={shuffleboardLogo} 
                />
    
            }

            
        </>



    );
}
