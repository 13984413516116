import React, { useState , useMemo, useEffect } from "react";
import useControllerBase from "../gamecontrollers/gameControllerBase";
import useInterval from "../helpers/UseInterval";

export default function Joystick(props){
    const {size, padSize, orientation} = props;
    const [inUse, setInUse] = useState(false); 
    const [Zone, setZone] = useState(null); 
    const [Pad, setPad] = useState(null);
    const [tX, setTX] = useState(0);
    const [tY, setTY] = useState(0);
    const [clampedX, setClampedX] = useState(0);
    const [clampedY, setClampedY] = useState(0);
    const [padAngle, setPadAngle] = useState(null);
    const [messageThreshold, setMessageThreshold] = useState(15);
    const [ori, setOri] = useState(null);

    
    const { 
        bgColor,
        joystickGlow,
        onAxisChange,
    } = useControllerBase(); 

    const padLocation = useMemo(() => { 
        return inUse ? {x: `${tX}px`, y: `${tY}px`} : 
    (Pad !== null ? {x: `calc(50% - ${Pad.width/2}px)`, y: `calc(50% - ${Pad.height/2}px)`} : {x: `calc(50% - ${60}px)`, y: `calc(50% - ${60}px)`} )
    
    }, [inUse, tX, tY, Pad]);

    const zoneColor = useMemo(() => {return padAngle != null ? `linear-gradient(${padAngle}deg, ${joystickGlow})`: '#2A2A2D'}, [padAngle]);
    
    useEffect(() => {
        var Zone = document.getElementById(`ZoneRect`).getBoundingClientRect();
        setZone(Zone);

        var Pad = document.getElementById(`PadRect`).getBoundingClientRect();
        setPad(Pad);

        var x = (Zone.width / 2) - (Pad.width/2);
        var y = (Zone.height / 2) - (Pad.height/2);

        setTX(x);
        setTY(y);

    }, [orientation]);

    useInterval(() => {
        if (inUse === false) return;
        onAxisChange(getAxisData(clampedX, clampedY));
      }, inUse === true ? messageThreshold : null);



    const onDown = (event) => {       
        if (inUse === false) setInUse(true);

        var cX = event.targetTouches[0].clientX;
        var cY = event.targetTouches[0].clientY;
        var pX = Zone.x + (Zone.width/2);
        var pY = Zone.y + (Zone.height/2);

        if(cX < Zone.left) cX = Zone.left;
        if(cX > Zone.right) cX = Zone.right;
        if(cY < Zone.top) cY = Zone.top;
        if(cY > Zone.bottom) cY = Zone.bottom;

        setTX(cX - (Zone.left + (padSize/2)));
        setTY(cY - (Zone.top + (padSize/2)));
        setClampedX(cX);
        setClampedY(cY);

        setPadAngle((Math.atan2(cY - pY, cX - pX) * 180 / Math.PI)-90);

        onAxisChange(getAxisData(cX, cY));
    }

    const onUp = (event) => {
        if (inUse === true) setInUse(false);

        var x = (Zone.width / 2) - (Pad.width/2);
        var y = (Zone.height / 2) - (Pad.height/2);

        setTX(x);
        setTY(y);
        setClampedX(x);
        setClampedY(y);
        setPadAngle(null);

        onAxisChange({x:0, y:0});
    }

    function TrackFinger(event){
        if(inUse === true){

        var cX = event.targetTouches[0].clientX;
        var cY = event.targetTouches[0].clientY;
        var pX = Zone.x + (Zone.width/2);
        var pY = Zone.y + (Zone.height/2);

        if(cX < Zone.left) cX = Zone.left;
        if(cX > Zone.right) cX = Zone.right;
        if(cY < Zone.top) cY = Zone.top;
        if(cY > Zone.bottom) cY = Zone.bottom;

        setTX(cX - (Zone.left + (padSize/2)));
        setTY(cY - (Zone.top + (padSize/2)));
        setClampedX(cX);
        setClampedY(cY);

        setPadAngle((Math.atan2(cY - pY, cX - pX) * 180 / Math.PI)-90);
        }
    }

    const getAxisData = (X, Y) => {
        var value = {
            x: (((X - Zone.x)  / Zone.width) * 2) -1, 
            y: -1 * ((((Y - Zone.y) / Zone.height) * 2) -1)
        }

        value.x = Math.round(value.x * 100) / 100;
        value.y = Math.round(value.y * 100) / 100;

        return {x: value.x, y: value.y};
    }

    const bounding = {
        position: 'absolute', 
        top: `5%`,
        left: `2.5%`,
        height: `85%`, maxHeight: '320px',
        aspectRatio: '1/1',
        zIndex: '10',

        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
    }

    const JoystickBase = {
        position: 'relative',
        width: '100%',
        height: '25%',
        top: '37.5%',
        borderRadius: '24px',
        background: zoneColor,
        outline: '4px outset white',
        boxShadow: '0 4px 32px 20px rgba(0,0,0, .25), inset 0 0 64px 64px rgba(200,200,200, .1)'
    }

    const JoystickRect = {
        position: 'absolute',
        width: '75%',
        height: '75%',
        top: '12.5%',
        left: '12.5%',
    }

    const JoystickPadOuter = {
        position: 'absolute',
        top: padLocation.y,
        left: padLocation.x,
        width: '50%', maxWidth: '120px',
        aspectRatio: '1/1',
        borderRadius: '50%',
        background: `linear-gradient( to bottom, white, rgba(180, 180, 180, 1))`,
        boxShadow: '0 4px 12px 8px rgba(0,0,0, .25), inset 0 0 12px 12px rgba(200,200,200, .1)',
        outline: '2px solid white'
    }

    const JoystickPadInner = {
        position: 'relative',
        top: '10%',
        left: '10%',
        width: '80%',
        height: '80%',
        borderRadius: '50%',
        background: `linear-gradient( to top, white, rgba(220,220,220,1))`,
    }



    return(

        <div style={{...bounding}} onTouchStart={onDown} onTouchEnd={onUp} onTouchMove={TrackFinger}>
            <div style={{...JoystickBase}} >
                <div id="ZoneRect" style={{...JoystickRect}}>
                    <div id="PadRect" style={{...JoystickPadOuter}} ><div style={{...JoystickPadInner}} /></div>
                </div>
            </div>
            <UnstuckButton />
        </div>

        
        


    );
}

function UnstuckButton(props){
    const {size} = props;
    const [pressed, setPressed] = useState(false);
  
    const { 
        bgColor,
        buttonGlow,
        onTouchStart_Button_4,
        onTouchEnd_Button_4,
    } = useControllerBase();

    const bColor = useMemo(() => {return pressed ? buttonGlow : 'white, rgba(180, 180, 180, 1)'}, [pressed]);
    const bTextColor = useMemo(() => {return pressed ? 'white' : bgColor}, [pressed]);
    const bTextShadow = useMemo(() => {return pressed ? '2px 2px 8px rgba(0,0,0, .75)' : '0 -2px 4px rgba(255,255,255, 1)'}, [pressed]);

    const onTouchStart = () => {
        setPressed(true);
        onTouchStart_Button_4();
    }

    const onTouchEnd = () => {
        setPressed(false);
        onTouchEnd_Button_4();

    }

    const bounding = {
        position: 'absolute', 
        bottom: '0',
        left: '0',
        height: '40px',
        width: '100%',
        zIndex: '10',

        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
    }

    const ButtonsBase = {
        position: 'relative',
        width: '60%',
        left: '20%',
        height: '100%',
        borderRadius: '12px',
        background: '#2A2A2D',
        outline: '2px outset white',
        boxShadow: '0 4px 32px 20px rgba(0,0,0, .25), inset 0 0 64px 64px rgba(200,200,200, .1)'
    }

    const ButtonOutter = {
        position: 'absolute',
        width: 'calc(100% - 12px)',
        height: 'calc(100% - 12px)',
        left: '6px',
        top: '6px',
        borderRadius: '6px',
        background: `linear-gradient( to bottom, white, rgba(180, 180, 180, 1))`,
        boxShadow: '0 4px 12px 8px rgba(0,0,0, .25), inset 0 0 12px 12px rgba(200,200,200, .1)',
        outline: '2px outset white'
    }

    const ButtonInner = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        top: '1px',
        left: '1px',
        borderRadius: 'inherit',
        background: `linear-gradient( to top, ${bColor})`,
    }

    const SpanStyle = {
        position: 'relative',
        margin: 'auto auto',
        
        pointerEvents: 'none', 
        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */

   
        fontSize: 'clamp(20pt, 2.5vw, 32pt)',
        fontWeight: '600',
    }

    return(

        <div style={{...bounding, }}>
            <div style={{...ButtonsBase}} >
                <div style={{...ButtonOutter}} >
                    <div style={{...ButtonInner, background: `linear-gradient( to top, ${bColor})`}} onTouchStart={() => onTouchStart()} onTouchEnd={() => onTouchEnd()}>
                        <span style={{...SpanStyle, color: bTextColor, textShadow: bTextShadow }}>UNSTUCK</span>
                    </div> 
                </div>
            </div>
        </div>

    );
}