import React, { Component } from "react";
import Context from './Context';
import { GenerateUniqueID } from "../helpers/GenerateUniquePlayeID";
import { GetPlayerIcon } from "../Sprites/PlayerIconLoader";

class GameContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        gameId: '',
        playerId: '',
        playerName: 'GolfMan3000',
        playerIcon: 'baseball',
        playerColor: 'charcoal',
        homeTeamColor: 'red',
        awayTeamColor: 'blue',
        selectedPlayerPuck: 0,
        spinValue: 50
      }
    }
  }

  componentDidMount() {   
    this.LoadContextInformation();
  }

  LoadContextInformation = () =>{
      //console.log("Device ID get here");
      //console.log("Inside of the check to setplayer inf");
        
      let ID = this.playerId;

      if(localStorage.getItem('PlayerId')){
        this.set('playerId', localStorage.getItem('PlayerId'));

      }else{
        if(ID === undefined || ID === null || ID === ''){
          ID = GenerateUniqueID();
          this.set('playerId', ID);
          localStorage.setItem('PlayerId', ID);
          //console.log("UNIQUE PLAYER ID HERE:" );
          //console.log(ID);
        }
      }
      


      let name = this.playerName;

      if(name == null){
          if(localStorage.getItem('PlayerName')){
            name = localStorage.getItem('PlayerName');
          }else{
            name = 'Unknown Player';
          }
          this.set('playerName', name);
      }
}

  changeValue = (value) => {
    this.setState({
      value
    })
  }

  set = (key, value) => {
    var context = this.state.context;
    context[key] = value;
    this.setState({
      context
    })
  }
  render() {
    const context = {
      user: this.state.context,
      set: this.set
    };

    return (
      <Context.Provider value={context}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default GameContextProvider;
