import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";

import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import { GenericReticule, sumoTouchArea, sumoBkgSplash, sumoLogo } from "../Sprites/ImagesLoader";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner } from "../Components/PagePrefabs";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameplayArea } from "../Components/GameplayPrefabs";


export default function Sumo(props){
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);

    
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context, useGameStatecontext, teamsContext);


    useEffect(() => {
        //OnMount Things happen here
        document.title = "Sumo Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        //console.log(context.user);
        JoinWaitingRoom();
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        //console.log(context.user.playerID);
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

   
    function onPayload(id, payload, senderContext){
        //console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload, senderContext);

        //Game specific calls
        switch(id){
           
          
            default:
                break;
        }
    }

   

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        //SendSpecificMessageToServer("Sumo_Tracking", payload);
    }

    function TrackingEnded(){
        SendSpecificMessageToServer("Sumo_Fire");
    }

    const TouchInfo = {
        bkg: sumoTouchArea,
        reticule: GenericReticule,
        sendArray: false,
        rate: 15,
        onUpdateTracking: ()=>{},
        onTrackingEnd: TrackingEnded

    }

    return (

    <>
        <PageBackground bkgImage={sumoBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!GameStateRef.current.joinedGame} Logo={sumoLogo}/>
        <PlayerBanner myColor={teamsContext.Teams.myteam.color}/>
        {!GameStateRef.current.teamsSelected ? 
            <TeamSelection TeamContext={teamsContext} GameState={GameStateRef.current} smts={(msg) => SendSpecificMessageToServer(msg)} /> 
            : 
            <GameplayArea game="Sumo" Team={teamsContext.Teams.myteam} OpposingTeam={teamsContext.Teams.otherteam} TouchBoxInfo={TouchInfo} Spectating={teamsContext.Teams.myteam === teamsContext.Teams.neutralteam} Logo={sumoLogo} />    
        }

    </>
       
    );
}
