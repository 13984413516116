import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import Context from './GameStateContext';
import { useGameState } from "../helpers/TeamSelectionHooks";

export default function GameStateContextProvider(props) {
    const gameState = useGameState();
    
    return (
        <Context.Provider value={gameState}>
          {props.children}
        </Context.Provider>
      );
}