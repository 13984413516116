import websocket from "./WebSocket";

export function useNetworkingHooks(UserContext) {

    function SendSpecificMessageToServer(Id, Payload){
    
        var msg = {
            id: Id,
            payload: JSON.stringify(Payload ?? ''),
            userContext: UserContext
        }

        websocket.Send(msg);
    }

    const JoinWaitingRoom = () => {
        SendSpecificMessageToServer("RequestToConnectToRoom");
        
    }

    const Disconnect = () => {
        SendSpecificMessageToServer("DiscontinueConnection");
    }

    const Reconnect = (callback) =>{
        SendSpecificMessageToServer("PhoneClientAttemptReconnect");
    }
    const RequestGameInformationData = () =>{
        SendSpecificMessageToServer("requestGameStateData");

    }

    const PressButton = (button) => {
        SendSpecificMessageToServer('ButtonDown', button);
    } 
    
    const ReleaseButton = (button) => {
        SendSpecificMessageToServer('ButtonUp', button);
    }
    
    const SendAxis= (axis) => {
        SendSpecificMessageToServer("UpdateAxisPosition", axis);
    }

    const CheckReconnectMessages = (id, payload, senderContext, callback) =>{
        switch(id){
            case 'phoneClientReconnectServerResponse':
                if(senderContext == null){
                    //console.log("Sending context is empty, sending to play screen");
                    //NAVIGATE TO THE PLAY SCREEN FROM HERE 
                    callback();
                }else{
                    JoinWaitingRoom(senderContext);

                }
            break;
            default:
                break;
        }
    }


    return { SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect, RequestGameInformationData, PressButton, ReleaseButton, SendAxis};
}


