import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext.js";

import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner, PlayerIcon, TextBox } from "../Components/PagePrefabs";
import { CarcadeLogo, SplashBackgroundImg } from "../Sprites/ImagesLoader.js"



export default function JoinScreen(props){
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);

    const [inputValue, setInputValue] = useState('');
    const [textValue, setTextValue] = useState('Enter Code to Join Lobby');
    
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context, gameStateContext, teamsContext);

    const DarkColor = useMemo(() => {return `color-mix(in srgb, gray, black 50%)`}, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setInputValue(code); // Set the code into the input field
        }

        //OnMount Things happen here
        document.title = "Join Carcade";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        //console.log(context.user);
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        //Disconnect();
    }

   
    function onPayload(id, payload, senderContext){
        //console.log(`Payload message recieved ${id}`, payload);
        CheckCoreGameConnectionMessages(id, payload, senderContext);

        //Game specific calls
        switch(id){
            case "phoneClientServerResponse":
                if(payload.sessionId !== null){
                    //console.log(`Session ID i recieved is the following ${payload.sessionId}`);
                   // websocket.sessionId = payload.sessionId;
                    //Then do Join Waiting Room. 
                    JoinWaitingRoom(context.user);


                }else{
                    //Display error Message
                    alert("Error connecting, please check your code and try again");
                }

                break;
            default:
                break;
        }
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    const inputStyle = {
        width: '100%', 
        height: '100%', 
        fontSize: 'x-large',
        textAlign: 'center',
        borderRadius: '12px',
        outline: 'solid 2px #FFD600' ,
        outlineOffset: '2px'
    }

    const submitStyle = {
        width: '100%',
        height: '100%',
        background: `linear-gradient(to right, gray 10%, ${DarkColor} 90%)`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px',

        fontSize: 'x-large',
        textShadow: '2px 2px 2px rgba(0,0,0, 0.375)',
        color: 'white'

    }

    

    ///This button will allow for the user to connect to the server. 
    const handleButtonClick = () => {
       // alert(`Text: ${textValue}, Input: ${inputValue}`);
       //console.log("Sending phone client request");
       sessionStorage.setItem("sessionCode", inputValue.toUpperCase());
        SendSpecificMessageToServer(`phoneClientServerJoinRequest`,`${inputValue.toUpperCase()}`);
      };
    return (
        <>
        <PageBackground
            bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"}
            bkgImage={SplashBackgroundImg} // Replace with your actual image
            useBkgGradient={true}
            useLogo={true}
            Logo={CarcadeLogo} // Replace with your actual logo
            useSmallLogo={false}
        />
        <div style={{
            display: 'flex',
            flexFlow: 'column noWrap',
            gap: '24px',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 1, // Ensure this content is on top
            padding: '20px',
            textAlign: 'center',
            color: 'white' // Change text color for visibility against the background
        }}>
            <TextBox width={'90%'} fontSize={"x-large"} textAlign={"center"} text={"Enter Code to Join Lobby"} bkgStyle={{height: '40px', margin: '0 auto'}} />
            <div style={{width: '80%', height: '56px', margin: '0 auto'}}>
                <input             
                    type='text'
                    placeholder="Input Code here..."
                    maxLength={4}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{...inputStyle}} // Add some padding for better appearance
                /> 
            </div>
            <div style={{width: '50%', height: '40px', margin: '0 auto'}}>
                <button onClick={handleButtonClick}
                style={{...submitStyle}}>
                    JOIN</button>
            </div>

            
        </div>
    </>
        


    );
}

//        <PageBackground bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"} bkgImage={SplashBackgroundImg} useBkgGradient useLogo Logo={CarcadeLogo} useSmallLogo  />

