import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import Context from './TeamsContext';
import { useTeamSelectionData } from "../helpers/TeamSelectionHooks";

export default function TeamsContextProvider(props) {
    const teams = useTeamSelectionData();
    
    return (
        <Context.Provider value={teams}>
          {props.children}
        </Context.Provider>
      );
}