import React, { useEffect, useMemo, useContext } from "react";  
import { useNetworkingHooks } from "../helpers/NetworkMessaging";
import Context from "../context/Context";

export default function useControllerBase(props) {
    const context = useContext(Context)
    const {PressButton, ReleaseButton, SendAxis} = useNetworkingHooks(context.user);

    const bgColor = useMemo(() => {
       if(context.user.playerColorDark == null || context.user.playerColorDark.trim() === '') return '#2A2A2D';
       else return context.user.playerColorDark;
    }, [context]);

    const buttonGlow = useMemo(() => {
        if(context.user.playerColorBright == null || context.user.playerColorBright.trim() === '') return '#2f9da1 0%, #185d60 85%';
        else return `${context.user.playerColorBright} 0%, ${context.user.playerColor} 85%`;
    }, [context]);

    const joystickGlow = useMemo(() => {
        if(context.user.playerColorBright == null || context.user.playerColorBright.trim() === '') return '#2f9da1 0%, #185d60 25%, #2A2A2D 50%';
        else return `${context.user.playerColorBright} 0%, ${context.user.playerColorDark} 25%, #2A2A2D 50%`
    }, [context]);

    return{      
        // 1
        onTouchStart_Button_1() {           
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Gas");
        },
        onTouchEnd_Button_1() {
            ReleaseButton("Gas");
        },

        // 2
        onTouchStart_Button_2() {
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton('Boost');
        },
        onTouchEnd_Button_2() {
            ReleaseButton("Boost");
        },

        // 3,
        onTouchStart_Button_3(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Brake");
        },
        onTouchEnd_Button_3(){
            ReleaseButton("Brake");
        },

        // 4
        onTouchStart_Button_4(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Unstuck");
        },
        onTouchEnd_Button_4(){
            ReleaseButton("Unstuck");
        },

        // 5
        onTouchStart_Button_5(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_5");
        },
        onTouchEnd_Button_5(){
            ReleaseButton("Button_5");
        },

        // 6
        onTouchStart_Button_6(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_6");
        },
        onTouchEnd_Button_6(){
            ReleaseButton("Button_6");
        },

        // 7
        onTouchStart_Button_7(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_7");
        },
        onTouchEnd_Button_7(){
            ReleaseButton("Button_7");
        },

        // 8
        onTouchStart_Button_8(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_8");
        },
        onTouchEnd_Button_8(){
            ReleaseButton("Button_8");
        },

        // Up
        onTouchStart_Button_UP(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_UP");
        },
        onTouchEnd_Button_UP(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(50);
            }
            ReleaseButton("Button_UP");
        },

        // Down
        onTouchStart_Button_DOWN(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_DOWN");
        },
        onTouchEnd_Button_DOWN(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(50);
            }
            ReleaseButton("Button_DOWN");
        },

        // Left
        onTouchStart_Button_LEFT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_LEFT");
        },
        onTouchEnd_Button_LEFT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(50);
            }
            ReleaseButton("Button_LEFT");
        },

        // Right
        onTouchStart_Button_RIGHT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_RIGHT");
        },
        onTouchEnd_Button_RIGHT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(50);
            }
            ReleaseButton("Button_RIGHT");
        },

        // Menu
        onTouchStart_Button_MENU(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_MENU");
        },
        onTouchEnd_Button_MENU(){
            ReleaseButton("Button_MENU");
        },

        // Exit
        onTouchStart_Button_EXIT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }
            
            PressButton("Button_EXIT");
        },
        onTouchEnd_Button_EXIT(){
            ReleaseButton("Button_EXIT");
        },

        // Select
        onTouchStart_Button_SELECT(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }

            PressButton("Button_SELECT");
            //SendOpenGame('IceHockey');
        },
        onTouchEnd_Button_SELECT(){
            ReleaseButton("Button_SELECT");
        },

        // Start
        onTouchStart_Button_START(){
            if (Boolean(window.navigator.vibrate)) {
                window.navigator.vibrate(100);
            }
            
            PressButton("Button_START");
        },
        onTouchEnd_Button_START(){
            ReleaseButton("Button_START");
        },

        //Axis Change
        onAxisChange(axis){
            SendAxis(axis);
        },

        bgColor,
        buttonGlow,
        joystickGlow
 
    }
}

